import style from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BigNumber from "bignumber.js";

import API from '../api/index.js';
import Loading from '../home/loading.js';
import background from "../images/home_bg.png";
import { ReactComponent as ClaimIcon } from '../images/claim.svg';
import { ReactComponent as InviteIcon } from '../images/invite.svg';
import { ReactComponent as ExploreIcon } from '../images/explore.svg';

function Index() {
  const [loading, setLoading] = useState(true);
  const [me, setMe] = useState({});

  useEffect(() => {
    let api = new API();
    api.me.remote(api.mixin.conversationId()).then((resp) => {
      if (resp.error) {
        return
      }
      setLoading(false);
      setMe(resp.data);
    })
  }, []);

  if (loading) {
    return <Loading />
  }

  const i18n = window.i18n;

  return (
    <div className={style.container} style={{backgroundImage: `url(${background})`}}>
      <header> { i18n.t('name') } </header>
      <div className={style.body}>
        <div className={ style.total }>
          { i18n.t('home.total') }
        </div>
        <div className={style.amount}>
          { me.total_amount }
          <span>BTC</span>
        </div>
        <div className={style.value}>
          ≈ ${BigNumber(me.price_usd).times(BigNumber(me.total_amount)).precision(2).toString()}
        </div>
        <div className={style.actions}>
          <Link to={ me.is_subsidy ? '/subsidies' : '/activations'} className={style.claim}>
            <span>
              <ClaimIcon />
            </span>
            { i18n.t('home.claim') }
          </Link>
          <Link to='/invitation' className={style.invite}>
            <span>
              <InviteIcon />
            </span>
            { i18n.t('home.invite') }
          </Link>
          <Link to='/explore' className={style.explore}>
            <span>
              <ExploreIcon />
            </span>
            { i18n.t('home.explore') }
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Index;

/*
  * https://github.com/MixinNetwork/lucky-btc-bot-web/blob/old/src/home/index.js
  * 1. only group can send envelope
  * 2. new user can only claim subsidies
*/
