import style from './index.module.scss';
import React, {Component} from 'react';
import Loading from '../home/loading.js';
import Navi from '../components/navi.js';
import API from '../api/index.js';

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conversations: [],
      submiting: false,
      loading: true,
    }

    this.api = new API();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.api.conversation.index().then((resp) => {
      if (resp.error) {
        return;
      }

      this.setState({conversations: resp.data, loading: false});
    });
  }

  handleClick(id, prev) {
    if (this.state.submiting) {
      return;
    }
    let locale = 'en';
    if (prev === locale) {
      locale = 'zh';
    }
    let conversations = this.state.conversations;
    for (let i=0;i < conversations.length; i++) {
      if (conversations[i].conversation_id === id) {
        conversations[i].submiting = true;
      }
    }
    this.setState({conversations: conversations, submiting: true}, () => {
      this.api.conversation.update(id, locale).then((resp) => {
        if (resp.error) {
          return;
        }

        for (let i=0;i < conversations.length; i++) {
          if (conversations[i].conversation_id === id) {
            conversations[i].submiting = false;
            conversations[i].locale = resp.data.locale;
          }
        }
        this.setState({conversations: conversations, submiting: false});
      });
    });
  }

  render() {
    const i18n = window.i18n;
    const state = this.state;

    if (state.loading) {
      return <Loading />
    }

    const conversations = state.conversations.map((c) => {
      return (
        <div key={c.conversation_id} className={style.item}>
          <div className={style.border}>
            <div className={style.name}>
              {c.name}
            </div>
            <span className={`${style.locale} ${style[c.locale]}`} onClick={() => {this.handleClick(c.conversation_id, c.locale)}}>
              {!c.submiting && i18n.t(`general.${c.locale}`)}
            </span>
          </div>
        </div>
      )
    });

    return (
      <div className={style.container}>
        <Navi to='/' text={i18n.t('conversation.head')} />
        <div className={style.body}>
          {conversations}
        </div>
      </div>
    )
  }
}

export default Index;
