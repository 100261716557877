import style from './index.module.scss';

import React, {Component} from 'react';
import * as moment from 'moment';
import { Redirect, Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import API from '../api/index.js';
import User from '../users/view.js';
import Loading from '../home/loading.js';
import background from '../images/claim_bg.png';

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      loading: true,
      submitting: false,
      recaptcha: '',
      site_key: '',
      claiming: false
    }
    this.api = new API();
    this.handleOption = this.handleOption.bind(this);
    this.handleClaim = this.handleClaim.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.recaptchaRef = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onExpired = this.onExpired.bind(this);
    this.claimSubsidy = this.claimSubsidy.bind(this);
  }

  componentDidMount() {
    this.api.task.show().then((resp) => {
      if (resp.error) {
        return;
      }
      let data = resp.data || {};
      data.loading = false;
      let remaining = moment.duration(moment.utc().endOf('day').diff(moment.utc()));
      data.remaining = remaining;
      this.setState(data, () => {
        this.setTimer();
      });
    });
  }

  setTimer() {
    if (!this.state.loading) {
      setTimeout(() => {
        if (this.state.has_subsidy) {
          return;
        }

        let d=moment.duration(moment.utc().startOf('day').diff(moment.utc(this.state.created_at)));
        let ready = d.asSeconds() > 0;
        let remaining = moment.duration(moment.utc().endOf('day').diff(moment.utc()));
        this.setState({has_subsidy: ready, remaining: remaining}, () => {
          this.setTimer();
        });
      }, 1000);
    }
  }

  handleOption(o) {
    this.setState({option: o});
  }

  handleClaim() {
    this.setState({submitting: true}, () => {
      this.api.task.create(this.state.option).then((resp) => {
        let data = resp.data || {};
        data.submitting = false;
        this.setState(data);
      });
    });
  }

  handleComplete() {
    this.setState({submitting: true}, () => {
      this.api.task.complete().then((resp) => {
        let data = resp.data || {};
        data.submitting = false;
        if (data.state === 'INITIALED') {
          data.checked = true;
        }
        data.has_subsidy = this.state.has_subsidy;
        this.setState(data);
      });
    });
  }

  onChange(token) {
    this.setState({recaptcha: token}, () => {
      this.api.subsidy.create(this.state).then((resp) => {
        if (resp.error) {
          this.setState({claiming: false});
          return;
        }

        let success = resp.data.type === 'subsidy';
        this.setState({claiming: false, has_subsidy: !success});
      });
    });
  }

  onExpired() {
    this.setState({recaptcha: ''});
  }

  claimSubsidy() {
    this.setState({claiming: true}, () => {
      this.recaptchaRef.current.execute();
    });
  }

  taskName(option) {
    let desc;
    switch(option) {
      case 'EXIN_KYC':
        desc = 'exin';
        break;
      case 'WITHDRAWAL_OR_DEPOSIT':
        desc = 'wod';
        break;
      case 'INVITATION':
        desc = 'invitation';
        break;
      case 'INVITATION_KYC':
        desc = 'invitation_limit';
        break;
      case 'INVITATION_NONE_KYC':
        desc = 'invitation_limit';
        break;
      case 'BALANCE':
        desc = 'balance';
        break;
      case 'EXIN_EARN':
        desc = 'exin_earn';
        break;
      default:
        desc = '';
        break;
    }
    return desc;
  }

  render() {
    const i18n = window.i18n;
    const state = this.state;

    if (state.loading) {
      return <Loading />
    }

    if (!!state.error_code) {
      return <User.Tips error_code={ state.error_code } insufficient_at={ state.insufficient_at } />
    }

    if (state.state === 'APPROVED' || state.state === 'DUPLICATED') {
      return <Redirect to='/' />
    }

    // description of the task
    let taskList;
    if (state.options && state.options.length > 0) {
      let tasks = state.options.map((option) => {
        return (
          <div className={ style.name } key={ option }>
            <Link to={this.props.myroute} onClick={() => this.handleOption(option)}>{i18n.t(`task.${this.taskName(option)}.title`)}</Link>
          </div>
        );
      });
      taskList = (
        <div className={ `shadow ${style.tasks}` }>
          <div className={style.title} dangerouslySetInnerHTML={{__html: i18n.t('task.title')}} />
          { tasks }
        </div>
      );
    }

    let taskShow = (
      <div className={ `shadow ${style.tasks}` }>
        <div className={ style.name }>
          <Link to={this.props.myroute} onClick={() => this.handleOption(state.option)}>{i18n.t(`task.${this.taskName(state.option)}.title`)}</Link>
        </div>
        <div className={ style.desc }>
          {/* count for invitation_limit, amount for balance */}
          <div dangerouslySetInnerHTML={{__html: i18n.t(`task.${this.taskName(state.option)}.desc`, {count: state.invitation_count, amount: state.balance})}} />
        </div>
        <div className={style.initialed}>
          { state.checked && <div>{i18n.t('task.initialed')}</div> }
        </div>
        <div className='action'>
          { state.state === 'COMPLETED' && state.option && <button onClick={this.handleClaim} className='button'>{i18n.t('task.claim')}</button> }
          { state.state === 'INITIALED' && <button onClick={this.handleComplete} className='button'>{i18n.t('task.complete')}</button> }
        </div>
      </div>
    );

    /* Has Subsidy */
    // There isn't task, state is NONE
    let blank = (
      <div className={ `shadow ${style.blank}` }>
        <div className={ style.body }>
          { i18n.t('task.none') }
        </div>
      </div>
    );

    // claim subsidy
    let subsidy = (
      <div className={ `shadow ${style.subsidy}` }>
        <div className={ style.title }>
          { i18n.t('subsidy.title') }
        </div>
        <div className={ style.amount }>
          <span>{ state.reward_incr_step }</span> BTC
        </div>
        <div className='action'>
          <button onClick={this.claimSubsidy} className={ `button` }>{ i18n.t('subsidy.claim') }</button>
        </div>
      </div>
    );

    // next
    let claimed = (
      <div className={ `shadow ${style.next}` }>
        <div className={ style.title }>
          { i18n.t('subsidy.next') }
        </div>
        <div className={ style.body }>
          <span> { ('00'+state.remaining.hours()).slice(-2) } </span>
          :
          <span> { ('00'+state.remaining.minutes()).slice(-2) } </span>
          :
          <span> { ('00'+state.remaining.seconds()).slice(-2) } </span>
        </div>
        <div className={ style.rewards } dangerouslySetInnerHTML={{__html: i18n.t('activation.countdown.rewards', {amount: state.reward_incr_step}) }} ></div>
        <div className='action'>
          <button className={ `button disable` }>{ i18n.t('subsidy.claim') }</button>
        </div>
      </div>
    );

    return (
      <div className='container' style={{backgroundImage: `url(${background})`}}>
        <User.Detail name={ i18n.t('task.head') } />

        <div className='section'>
          { state.state === 'COMPLETED' && state.options.length === 0 && blank }
          { state.state === 'COMPLETED' && state.options.length > 0 && state.option === '' && taskList }
          { state.option !== '' && taskShow }
          {/* TODO: insufficient_balance should be fixed */}
          { state.state === 'INITIALED' && !state.insufficient_balance && state.has_subsidy && subsidy }
          { state.state === 'INITIALED' && !state.insufficient_balance && (state.option.includes('INVITATION_') || state.option === 'BALANCE') && !state.has_subsidy && claimed }
        </div>

        <ReCAPTCHA
          ref={this.recaptchaRef}
          size='invisible'
          onChange={this.onChange}
          onExpired={this.onExpired}
          sitekey={state.site_key}
        />
      </div>
    )
  }
}

export default Index;

/*
  * https://github.com/MixinNetwork/lucky-btc-bot-web/blob/old/src/tasks/index.js
  * error code
  * APPROVED redirect to /
  * Without tasks
  * COMPLETED and option = '', TODO should use other state
  * option != '', 1. state = 'COMPLETED' and state.option, 2. state = 'INITIALED'
  * has_subsidy
  * insufficient_balance TODO
*/
