import axios from 'axios';
import Noty from 'noty';
import Mixin from './mixin.js'
import Config from './config.js'
import Activation from './activation.js'
import Conversation from './conversation.js'
import Me from './me.js'
import Packet from './packet.js'
import Subsidy from './subsidy.js'
import Task from './task.js'
import Invitation from './invitation.js'

Noty.overrideDefaults({
  type: 'error',
  layout: 'topCenter',
  killer: true,
  theme: 'nest',
  timeout: 1000,
  progressBar: false,
  animation: {
    open : 'noty_effects_open',
    close: 'noty_effects_close'
  }
});

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(function(config) {
  config.url = '/api' + config.url
  config.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem('token')}`;
  return config
}, function(error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function(response) {
  if (!!response.status && (response.status >= 200 && response.status < 300)) {
    const data = response.data;
    if (!!data.error) {
      const error = data.error;
      new Noty({
        text: window.i18n.t(`errors.${error.code}`)
      }).show();
      if (error.code === 401 || error.code === 10013) {
        window.localStorage.removeItem('user');
        // TODO
        let obj = new URL(window.location);
        var returnTo = encodeURIComponent(obj.href.substr(obj.origin.length));
        window.location.replace(`https://mixin-www.zeromesh.net/oauth/authorize?client_id=${Config.ClientId}&scope=PROFILE:READ+PHONE:READ+ASSETS:READ+SNAPSHOTS:READ&response_type=code&return_to=` + returnTo);
      } else if (error.code === 404) {
        window.location.href = '/404'
      }
    }
    return data;
  }
  return response
}, function(error) {
  let status, data;
  // TODO: should clear error.request and error
  if (error.response) {
    status = error.response.status;
    data = error.response.data;
  } else if (error.request) {
    status = 500;
    data = 'Initialize request error';
  } else {
    status = 500;
    data = error.message;
  }
  new Noty({
    text: window.i18n.t(`errors.${status}`)
  }).show();
  return {error: {code: status, description: data}};
});

class API {
  constructor() {
    this.axios = axios;
    this.mixin = new Mixin();
    this.me = new Me(this);
    this.packet = new Packet(this);
    this.conversation = new Conversation(this);
    this.activation = new Activation(this);
    this.task = new Task(this);
    this.subsidy = new Subsidy(this);
    this.invitation = new Invitation(this);
  }
}

export default API;
