import style from './navi.module.scss';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { ArrowLeft } from "baseui/icon";

class Navi extends Component {
  render() {
    return (
      <div className={style.navi}>
        <Link to={this.props.to} className={style.back}>
          <ArrowLeft size={28} color='black' />
        </Link>
        <div className={style.head}>
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default Navi;
