import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './home/view.js';
import User from './users/view.js';
import Packet from './packets/view.js';
import Activation from './activations/view.js';
import Conversation from './conversations/view.js';
import Subsidy from './subsidies/view.js';
import Task from './tasks/view.js';
import Invitation from './invitations/view.js';
import Explore from './explore/view.js';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={ Home.Index } />
        <Route exact path='/rules' component={ Home.Rule } /> {/* deprecated */}
        <Route exact path='/deposit' component={ Home.Deposit } /> {/* deprecated */}
        <Route exact path='/auth/callback' component={ User.Oauth } />
        <Route exact path='/activations' component={ Activation.Index } />
        <Route exact path='/conversations' component={ Conversation.Index } /> {/* deprecated */}
        <Route exact path='/packets/prepare' component={ Packet.Prepare } />
        <Route exact path='/packets/:id' component={ Packet.Show } />
        <Route exact path='/subsidies' component={ Subsidy.Index } />
        <Route exact path='/tasks' component={ Task.Index } />
        <Route exact path='/invitation' component={ Invitation.Show } />
        <Route exact path='/explore' component={ Explore.Show } />
      </Switch>
    </Router>
  );
}

export default App;
