import style from './deposit.module.scss';
import React, {Component} from 'react';
import Navi from '../components/navi.js';

class Deposit extends Component {
  render() {
    const i18n = window.i18n;

    return (
      <div className={style.container}>
        <Navi to='/' text={i18n.t('deposit.head')} />
        <div className={style.body} dangerouslySetInnerHTML={{__html: i18n.t('deposit.body')}} />
      </div>
    )
  }
}

export default Deposit;
