class Invitation {
  constructor(api) {
    this.api = api;
  }

  show() {
    return this.api.axios.get('/invitation');
  }
}

export default Invitation;
