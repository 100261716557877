import style from './show.module.scss';
import React, {Component} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import API from '../api/index.js';
import Loading from '../home/loading.js';
import OpenedBG from '../images/packet_opened_bg.png';
import ClosedBG from '../images/packet_bg.png';

class Show extends Component {
  constructor(props) {
    super(props);

    this.api = new API();
    this.state = {
      packet_id: props.match.params.id,
      loading: true,
      current: this.api.me.local(),
      recaptcha: '',
      site_key: '',
      is_claimed: false,
      submitting: false,
    }

    this.onChange = this.onChange.bind(this);
    this.handleClaim = this.handleClaim.bind(this);
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.api.packet.show(this.state.packet_id).then((resp) => {
      resp.data.loading = false;
      for (let i=0;i < resp.data.participants.length;i++) {
        if (resp.data.participants[i].user_id === this.state.current.user_id) {
          resp.data.participant = resp.data.participants[i];
          resp.data.is_claimed = true;
          break;
        }
      }
      if (resp.data.state === 'EXPIRED' || resp.data.state === 'REFUNDED') {
        resp.data.is_claimed = true;
      }
      this.setState(resp.data);
    });
  }

  handleClaim() {
    if (this.state.submitting) {
      return;
    }

    this.setState({submitting: true}, () => {
      this.recaptchaRef.current.execute();
    });
  }

  onChange(token) {
    const state =  this.state;
    this.setState({recaptcha: token}, () => {
      this.api.packet.claim(state.packet_id, this.state).then((resp) => {
        if (resp.error) {
          this.recaptchaRef.current.reset();
          this.setState({submitting: false});
          return;
        }
        for (let i=0;i < resp.data.participants.length;i++) {
          if (resp.data.participants[i].user_id === state.current.user_id) {
            resp.data.participant = resp.data.participants[i];
            resp.data.is_claimed = true;
            break;
          }
        }
        resp.data.submitting = false;
        this.setState(resp.data);
      });
    });
  }

  render() {
    const i18n = window.i18n;
    const state = this.state;

    if (state.loading) {
      return <Loading />
    }

    // The packet is waiting to open
    let claim = (
      <div className={style.profile}>
        <img src={state.user.avatar_url} alt={state.user.full_name} className={style.avatar} />
        <div className={style.name}>
          {state.user.full_name}
        </div>
        <div className={style.greeting}>
          {state.greeting}
        </div>
        <div className={style.claim}>
          <button onClick={this.handleClaim} className={ `${ style.button } ${ state.submitting ? style.submitting : '' }`} >{i18n.t('packet.claim')}</button>
        </div>
      </div>
    )

    let participants = state.participants.map((p) => {
      return (
        <div key={p.user_id} className={style.item}>
          <img src={p.avatar_url} alt={p.full_name} className={style.pavatar} />
          <div className={style.puser}>
            <div className={style.pname}>
              {p.full_name}
            </div>
            <div className={style.ptime}>
              {this.api.mixin.formatTime(p.created_at)}
            </div>
          </div>
          <div className={style.amount}>
            <span>{p.amount}</span> {state.asset.symbol}
          </div>
        </div>
      );
    });

    let participant;
    if (state.participant) {
      participant = (
        <div className={ style.info }>
          <div className={ style.amount }>
            {state.participant.amount} <span className={style.symbol}>{state.asset.symbol}</span>
          </div>
          <div className={style.tips}>
            {i18n.t('packet.tips.lucky')}
          </div>
        </div>
      );
    };

    if (!state.participant && (state.state === 'EXPIRED' || state.state === 'REFUNDED')) {
      participant = (
        <div className={style.expired}>
          {i18n.t('packet.expired')}
        </div>
      );
    }

    let opened = (
      <div className={ style.opened }>
        <div className={style.inviter}>
          <img src={state.user.avatar_url} alt={state.user.full_name} className={style.avatar} />
          <div className={style.name}>
            {state.user.full_name}
          </div>
          <div className={style.greeting}>
            {state.greeting}
          </div>
        </div>
        <div class='section'>
          <div className={ `shadow ${style.participant}`}>
            { participant }
          </div>

          <div className={ `shadow ${style.list}` }>
            <div className={style.status}>
              {i18n.t('packet.opened', {opened_count: state.opened_count, total_count: state.total_count, opened_amount: state.opened_amount, amount: state.amount, symbol: state.asset.symbol})}
            </div>
            { participants }
          </div>
        </div>
      </div>
    );

    return (
      <div className='container' style={{ backgroundImage: `url(${state.is_claimed ? OpenedBG : ClosedBG})`}}>

        { !state.is_claimed && claim }

        { state.is_claimed && opened }

        <ReCAPTCHA
          ref={ this.recaptchaRef }
          size='invisible'
          onChange={ this.onChange }
          sitekey={ state.site_key }
        />
      </div>
    );
  }
}

export default Show;
