class Packet {
  constructor(api) {
    this.api = api;
  }

  prepare(conversationId) {
    return this.api.axios.get(`/packets/prepare/${conversationId}`);
  }

  create(params) {
    const data = {conversation_id: params.conversation_id, asset_id: params.asset.asset_id, amount: params.amount, total_count: parseInt(params.count), greeting: params.greeting, recaptcha: params.recaptcha};
    return this.api.axios.post('/packets', data);
  }

  show(id) {
    return this.api.axios.get(`/packets/${id}`);
  }

  claim(id, params) {
    const data = {recaptcha: params.recaptcha};
    return this.api.axios.post(`/packets/${id}/claim`, data);
  }
}

export default Packet;
