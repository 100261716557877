import style from './prepare.module.scss';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import API from '../api/index.js';
import Config from '../api/config.js';
import Mixin from '../api/mixin.js';
import Loading from '../home/loading.js';
import background from '../images/claim_bg.png';

class Prepare extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conversation_id: new Mixin().conversationId(), // TODO
      assets: [],
      asset: {},
      conversation: {},
      amount: '',
      count: '',
      greeting: window.i18n.t('packet.prepare.memo'),
      packet_id: '',
      state: 'INITIAL',
      recaptcha: '',
      site_key: '',
      loading: true,
      submitting: '',
    };

    this.api = new API();
    this.onChange = this.onChange.bind(this);
    this.onExpired = this.onExpired.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.api.packet.prepare(this.state.conversation_id).then((resp) => {
      if (resp.error) {
        return;
      }

      resp.data.asset = resp.data.assets[0];
      for (let i=0;i<resp.data.assets.length;i++) {
        // TODO for test in local
        if (resp.data.assets[i].asset_id==='965e5c6e-434c-3fa9-b780-c50f43cd955c') {
          resp.data.asset = resp.data.assets[i];
          break;
        }
      };

      resp.data.loading = false;
      this.setState(resp.data);
    });
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit() {
    if (this.state.submitting !== '') {
      return;
    }
    this.setState({ submitting: 'submitting' }, () => {
      this.recaptchaRef.current.execute();
    });
  }

  handleCheck(id) {
    this.api.packet.show(id).then((resp) => {
      if (resp && resp.data && resp.data.state === 'PAID') {
        this.setState({packet_id: resp.data.packet_id, state: resp.data.state});
      } else {
        setTimeout(() => {
          this.handleCheck(id)
        }, 2000);
      }
    });
  }

  onChange(token) {
    this.setState({recaptcha: token}, () => {
      this.api.packet.create(this.state).then((resp) => {
        if (resp.error) {
          this.recaptchaRef.current.reset();
          this.setState({ submitting: '' });
          return;
        }

        const packet = resp.data;
        console.log(`https://mixin.one/pay?recipient=${Config.ClientId}&asset=${packet.asset.asset_id}&amount=${packet.amount}&trace=${packet.packet_id}&memo=${encodeURIComponent(packet.greeting)}`); // TODO
        window.location.href = `mixin://pay?recipient=${Config.ClientId}&asset=${packet.asset.asset_id}&amount=${packet.amount}&trace=${packet.packet_id}&memo=${encodeURIComponent(packet.greeting)}`;
        this.handleCheck(packet.packet_id);
      });
    });
  }

  onExpired() {
    this.setState({recaptcha: ''});
  }

  render() {
    const i18n = window.i18n;
    const state = this.state;

    if (state.loading) {
      return <Loading />
    }

    if (state.packet_id && state.state === 'PAID') {
      return <Redirect to={`/packets/${state.packet_id}`} />
    }

    // insufficient balance
    let deposit = (
      <div className='shadow'>
        <div className={style.body} dangerouslySetInnerHTML={{__html: i18n.t('deposit.body')}} />
      </div>
    );

    let packet = (
      <div className='shadow'>
        <div className={ style.detail }>
          <img src={state.asset.icon_url} alt={state.asset.symbol} className={style.icon} />
          <div className={ style.name }>
            { state.asset.name }
          </div>
          <div className={ style.balance }>
            { state.asset.balance } BTC
          </div>
        </div>

        <div className={ style.form }>
          <div className={ style.group }>
            <input type='number' name='amount' placeholder={ i18n.t('packet.prepare.amount') } value={state.amount} onChange={this.handleChange} />
            <label> BTC </label>
          </div>
          <div className={ style.group }>
            <input type='number' name='count' placeholder={ i18n.t('packet.prepare.count', {count: state.conversation.participants_count}) } value={ state.count } onChange={this.handleChange} />
            <label> { i18n.t('packet.prepare.title.count') } </label>
          </div>
          <div className={ style.group }>
            <input name='greeting' placeholder={ i18n.t('packet.prepare.greeting') } value={ state.greeting } onChange={this.handleChange} />
          </div>
          <div className={ style.notice }>
            {i18n.t('packet.prepare.notice', {amount: state.total_amount})}
          </div>
        </div>

        <div className='action'>
          <button onClick={this.handleSubmit} className={`button ${state.submitting}`}>{i18n.t('packet.prepare.submit')}</button>
        </div>
      </div>
    );

    return (
      <div className='container' style={{backgroundImage: `url(${background})`}} >
        <div className={ `section ${style.packet}`}>
          { !state.loading && state.assets.length <= 0 && deposit }
          { state.assets.length > 0 && packet }
        </div>
        {/*<div className={style.notice}>
            {i18n.t('packet.prepare.notice', {amount: state.total_amount})}
          </div>*/}
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size='invisible'
          onChange={this.onChange}
          onExpired={this.onExpired}
          sitekey={state.site_key}
        />
      </div>
    )
  }
}

export default Prepare;

/*
  * https://github.com/MixinNetwork/lucky-btc-bot-web/blob/old/src/packets/prepare.js
  * PAID
  * assets = 0 then deposit
  * notice, TODO 防止断签提示
*/
