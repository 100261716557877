import './index.scss';
import Locale from './locales/index.js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

window.i18n = new Locale(navigator.language);
document.title = window.i18n.t('name');
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

ReactDOM.render(<App /> , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
