class Conversation {
  constructor(api) {
    this.api = api;
  }

  index() {
    return this.api.axios.get('/conversations')
  }

  update(id, locale) {
    const data = {locale: locale};
    return this.api.axios.post(`/conversations/${id}`, data);
  }
}

export default Conversation;
