import Base64 from './base64.js';

class Me {
  constructor(api) {
    this.api = api;
    this.base64 = new Base64();
  }

  signIn(params) {
    return this.api.axios.post('/account', {code: params.code}).then((resp) => {
      if (resp.error) {
        return resp;
      }
      window.localStorage.setItem('token', resp.data.authentication_token);
      window.localStorage.setItem('user', this.base64.encode(JSON.stringify(resp.data)));
      return resp;
    });
  }

  remote(id) {
    return this.api.axios.get(`/me?c=${id}`).then((resp) => {
      if (resp.error) {
        return resp;
      }
      window.localStorage.setItem('token', resp.data.authentication_token);
      window.localStorage.setItem('user', this.base64.encode(JSON.stringify(resp.data)));
      return resp;
    });
  }

  local() {
    const user = window.localStorage.getItem('user');
    if (!user) {
      return {};
    }
    return JSON.parse(this.base64.decode(user));
  }

  loggedIn() {
    const user = this.local();
    return user.user_id !== undefined;
  }
}

export default Me;
