class Activation {
  constructor(api) {
    this.api = api;
  }

  create(params) {
    const data = {'recaptcha': params.recaptcha};
    return this.api.axios.post('/activations', data);
  }

  last() {
    return this.api.axios.get('/activations/last');
  }
}

export default Activation;
