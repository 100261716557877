import style from './error.module.scss';
import React, { Component } from 'react';
import BigNumber from "bignumber.js";

import API from '../api/index.js';
import Loading from '../home/loading.js';
import background from '../images/claim_bg.png';

// Display the errors of user
class Tips extends Component {
  constructor(props) {
    super(props);

    this.api = new API();
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.api.me.remote().then((resp) => {
      if (resp.error) {
        return;
      }

      resp.data.loading = false;
      this.setState(resp.data);
    });
  }

  render() {
    const i18n = window.i18n;
    const state = this.state;

    if (state.loading) {
      return <Loading />;
    }

    return (
      <div className='container' style={{backgroundImage: `url(${background})`}}>
        <div className={style.detail}>
          <div className={style.title}>
            { i18n.t('home.total') }
          </div>
          <div className={ style.amount }>
            <span> { state.total_amount } </span> BTC
          </div>
          <div className={ style.value }>
            ≈ ${BigNumber(state.price_usd).times(BigNumber(state.total_amount)).precision(2).toString()}
          </div>
        </div>

        <div className='section'>
          <div className={`shadow ${style.error}`}>
            <div dangerouslySetInnerHTML={{__html: i18n.t(`activation.errors.${this.props.error_code}`, { date:  this.props.insufficient_at})}} />
            <div className={ style.action }>
              <button className={ style.claim }>{ i18n.t('activation.expect.claim') }</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Tips;
