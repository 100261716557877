import style from './index.module.scss';

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import * as moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';

import API from '../api/index.js';
import Loading from '../home/loading.js';
import User from '../users/view.js';
import background from '../images/claim_bg.png';

class Index extends Component {
  constructor(props) {
    super(props);

    this.api = new API();
    this.state = {
      recaptcha: '',
      site_key: '',
      submitting: '',
      loading: true,
    };

    this.recaptchaRef = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onExpired = this.onExpired.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.api.subsidy.last().then((resp) => {
      if (resp.error) {
        return;
      }

      let d=moment.duration(moment.utc().startOf('day').diff(moment.utc(resp.data.created_at)));
      resp.data.ready = d.asSeconds() > 0;
      let remaining = moment.duration(moment.utc().endOf('day').diff(moment.utc()));
      resp.data.remaining = remaining;
      resp.data.loading = false;
      this.setState(resp.data, () => {
        this.setTimer();
      });
    });
  }

  handleSubmit() {
    if (this.state.submitting !== '') {
      return;
    }
    this.setState({submitting: 'submitting'}, () => {
      this.recaptchaRef.current.execute();
    });
  }

  onChange(token) {
    this.setState({recaptcha: token}, () => {
      this.api.subsidy.create(this.state).then((resp) => {
        if (resp.error) {
          this.setState({submitting: ''});
          return;
        }

        resp.data.ready = false;
        resp.data.submitting = '';
        this.setState(resp.data, () => {
          this.setTimer();
        });
      });
    });
  }

  onExpired() {
    this.setState({recaptcha: ''});
  }

  setTimer() {
    if (!this.state.loading) {
      setTimeout(() => {
        if (this.state.ready) {
          return;
        }

        let d=moment.duration(moment.utc().startOf('day').diff(moment.utc(this.state.created_at)));
        let ready = d.asSeconds() > 0;
        let remaining = moment.duration(moment.utc().endOf('day').diff(moment.utc()));
        this.setState({ready: ready, remaining: remaining}, () => {
          this.setTimer();
        });
      }, 1000);
    }
  }

  render() {
    const i18n = window.i18n;
    const state = this.state;

    if (state.loading) {
      return  <Loading />
    }

    if (!!state.error_code) {
      return <User.Tips error_code={ state.error_code } insufficient_at={ state.insufficient_at } />
    }

    if (state.has_task) {
      return <Redirect to='/tasks' />
    }

    // claim subsidy
    let subsidy = (
      <div className={ `shadow ${style.subsidy}` }>
        <div className={ style.title }>
          { i18n.t('subsidy.title') }
        </div>
        <div className={ style.amount }>
          <span>{ state.amount }</span> BTC
        </div>
        <div className='action'>
          <button onClick={this.handleSubmit} className={ `button ${state.submitting}` }>{ i18n.t('subsidy.claim') }</button>
        </div>
      </div>
    );

    // next
    let claimed = (
      <div className={ `shadow ${style.next}` }>
        <div className={ style.title }>
          { i18n.t('subsidy.next') }
        </div>
        <div className={ style.body }>
          <span> { ('00'+state.remaining.hours()).slice(-2) } </span>
          :
          <span> { ('00'+state.remaining.minutes()).slice(-2) } </span>
          :
          <span> { ('00'+state.remaining.seconds()).slice(-2) } </span>
        </div>
        <div className={ style.rewards } dangerouslySetInnerHTML={{__html: i18n.t('activation.countdown.rewards', {amount: state.amount}) }} >
            </div>
        <div className='action'>
          <button className={ `button disable` }>{ i18n.t('subsidy.claim') }</button>
        </div>
      </div>
    );

    return (
      <div className='container' style={{ backgroundImage: `url(${background})`}}>
        <User.Detail name={ i18n.t('subsidy.head')} />
        <div className={ `section` }>
          { state.ready && subsidy }
          { !state.ready && claimed }
        </div>
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size='invisible'
          onChange={this.onChange}
          onExpired={this.onExpired}
          sitekey={state.site_key}
        />
      </div>
    );
  }
}

export default Index;

/*
  * https://github.com/MixinNetwork/lucky-btc-bot-web/blob/old/src/subsidies/index.js
  * error code
  * has_task redirect to /tasks
  * ready claim
  * claimed
*/
