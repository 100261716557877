import style from './show.module.scss';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import background from '../images/explore_bg.png';
import { ReactComponent as ReturnIcon } from '../images/return.svg';
import TaskWallIcon from '../images/card_01.png';
import ExinEarnIcon from '../images/card_02.png';
import MixSwapIcon from '../images/card_03.png';

class Show extends Component {
  render() {
    const i18n = window.i18n;

    return (
      <div className='container' style={{backgroundImage: `url(${background})`}}>
        <div className={ style.navi }>
          <Link to="/"> <ReturnIcon /> { i18n.t('explore.head') }</Link>
        </div>
        <div className='section'>
          <div className={ style.card }>
            <img src={ TaskWallIcon } alt='TaskWall' />
            <div className={ style.detail }>
              <div className={ `${style.title} ${style.taskwall}` }>
                { i18n.t('explore.taskwall.title') }
              </div>
              <div>
                <a className={ style.taskwall } href={i18n.t('explore.taskwall.address') }> TRY NOW </a>
              </div>
            </div>
          </div>
          {
            navigator.language && navigator.language.indexOf('zh') >= 0 &&
              <div className={ style.card }>
                <img src={ ExinEarnIcon } alt='ExinEarn' />
                <div className={ style.detail }>
                  <div className={ `${style.title} ${style.exinearn}` }>
                    { i18n.t('explore.exinearn.title') }
                  </div>
                  <div>
                    <a className={style.exinearn} href={ i18n.t('explore.exinearn.address') }> TRY NOW </a>
                  </div>
                </div>
              </div>
          }
          <div className={ style.card }>
            <img src={ MixSwapIcon } alt='MixSwap' />
            <div className={ style.detail }>
              <div className={ `${style.title} ${style.mixswap}` }>
                { i18n.t('explore.mixswap.title') }
              </div>
              <div>
                <a className={style.mixswap} href={ i18n.t('explore.mixswap.address') }> TRY NOW </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Show;
