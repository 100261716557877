import style from './detail.module.scss';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BigNumber from "bignumber.js";

import API from '../api/index.js';
import { ReactComponent as ReturnIcon } from '../images/return.svg';

class Detail extends Component {
  constructor(props) {
    super(props);

    this.api = new API();
    this.state = {
      total_amount: 0,
      price_usd: 0,
    };
  }

  componentDidMount() {
    this.api.me.remote().then((resp) => {
      if (resp.error) {
        return;
      }

      this.setState(resp.data);
    });
  }

  render() {
    const i18n = window.i18n;
    const state = this.state;

    return (
      <div className={ style.detail }>
        <div className={ style.navi }>
          <Link to="/"> <ReturnIcon /> {this.props.name}</Link>
        </div>
        <div className={style.title}>
          { i18n.t('home.total') }
        </div>
        <div className={ style.amount }>
          <span> { state.total_amount } </span> BTC
        </div>
        <div className={ style.value }>
          ≈ ${ BigNumber(state.price_usd).times(BigNumber(state.total_amount)).precision(2).toString() }
        </div>
      </div>
    )
  }
}


export default Detail;
