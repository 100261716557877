import style from './rule.module.scss';
import React, {Component} from 'react';
import Navi from '../components/navi.js';

class Rule extends Component {
  render() {
    const i18n = window.i18n;

    return (
      <div className={style.container}>
        <Navi to='/' text={i18n.t('rules.head')} />
        <div className={style.body} dangerouslySetInnerHTML={{__html: i18n.t('rules.body')}} />
        <div className={style.copyright}>
          <i>{i18n.t('rules.copyright')}</i>
        </div>
      </div>
    )
  }
}

export default Rule;
