class Subsidy {
  constructor(api) {
    this.api = api;
  }

  create(params) {
    const data = {'recaptcha': params.recaptcha};
    return this.api.axios.post('/subsidies', data);
  }

  last() {
    return this.api.axios.get('/subsidies/last');
  }
}

export default Subsidy;
