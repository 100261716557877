const md5ToUuid = require('md5-to-uuid');
const md5 = require('md5');

class Mixin {

  getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  environment() {
    if (process.env.NODE_ENV === 'development') {
      return 'iOS';
    }
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.MixinContext) {
      return 'iOS';
    }
    if (window.MixinContext && window.MixinContext.getContext) {
      return 'Android';
    }
    return undefined;
  }

  appVersion() {
    if (process.env.NODE_ENV === 'development') {
      return '1.0.0';
    }
    let ctx;
    switch (this.environment()) {
      case 'iOS':
        ctx = prompt('MixinContext.getContext()');
        return JSON.parse(ctx).app_version;
      case 'Android':
        ctx = window.MixinContext.getContext();
        return JSON.parse(ctx).app_version;
      default:
        return undefined;
    }
  }

  conversationId() {
    if (process.env.NODE_ENV === 'development') {
      return '302da0b6-6fd2-4a23-a2cb-d3cb2a3627bf';
    }
    let ctx;
    switch (this.environment()) {
      case 'iOS':
        ctx = prompt('MixinContext.getContext()');
        return JSON.parse(ctx).conversation_id;
      case 'Android':
        ctx = window.MixinContext.getContext();
        return JSON.parse(ctx).conversation_id;
      default:
        return undefined;
    }
  }

  uniqueconversationId(oid, uid) {
    let minId = oid;
    let maxId = uid;
    if (oid > uid) {
      minId = uid;
      maxId = oid;
    }
    let m = this.hexByteStringToByteArray(md5(minId+maxId));
    m[6] = (m[6] & parseInt('0x0f', 16)) | parseInt('0x30', 16);
    m[8] = (m[8] & parseInt('0x3f', 16)) | parseInt('0x80', 16);
    return md5ToUuid(this.byteArrayToString(m));
  }

  hexByteStringToByteArray(hexByteString) {
    let bytes = new Array(16); // this could also be a new Uint8array(16)
    for (let i=0; i < hexByteString.length;) {
      let hexByte = hexByteString[i++] + hexByteString[i++];
      let byte = parseInt(hexByte, 16);
      bytes[i / 2 - 1] = byte;
    }
    return bytes;
  }

  byteArrayToString(arr) {
    let s='';
    for (let i=0;i < arr.length;i++) {
      let l = arr[i].toString(16);
      if (l.length===1) {
        l = '0' + l;
      }
      s+=l
    }
    return s
  }

  formatTime(time) {
    var date = new Date(time);
    if (date.setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)) {
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      var month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      return day + '/' + month + '/' + (date.getYear() - 100);
    }
    date = new Date(time);
    var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return hour + ':' + minute + ':' + second;
  }
}

export default Mixin;
