import style from './loading.module.scss';
import React, {Component} from 'react';
import Loader from "react-loader-spinner";

class Loading extends Component {
  render () {
    let i18n = window.i18n;

    let metaThemeColor = document.querySelector('meta[name=theme-color]');
    if (window.location.pathname === '/') {
      metaThemeColor.setAttribute('content', '#FFD6BF');
    } else {
      metaThemeColor.setAttribute('content', '#F77E33');
    }

    return (
      <div className={style.full}>
        <Loader
          type="Oval"
          color="#FF8333"
          height={60}
          width={60}
          timeout={0} //3 secs
        />
        <div className={style.loading}>
          {i18n.t('general.loading')}
        </div>
      </div>
    );
  }
}

export default Loading;
