import style from './show.module.scss';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import API from '../api/index.js';
import User from '../users/view.js';
import Loading from '../home/loading.js';
import background from '../images/claim_bg.png';

class Show extends Component {
  constructor(props) {
    super(props);

    this.api = new API();
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.api.invitation.show().then((resp) => {
      if (resp.error) {
        return;
      }

      resp.data.loading = false;
      this.setState(resp.data);
    });
  }

  render() {
    const i18n = window.i18n;
    const state = this.state;

    if (state.loading) {
      return <Loading />
    }

    return (
      <div className='container' style={{backgroundImage: `url(${background})`}}>
        <User.Detail name={ i18n.t('invitation.head') } />

        <div className='section'>
          <div className={ `shadow ${style.invitation}`}>
            <div className={ style.title }>
              { i18n.t('invitation.title') }
            </div>
            <div className={ style.count }>
              { state.count }
            </div>
            {
              state.inviter.identity_number !== "" && (
                <div className={ style.inviter }>
                  <div dangerouslySetInnerHTML={{__html: i18n.t('invitation.inviter', {inviter: state.inviter.identity_number})}} />
                </div>
              )
            }
          </div>

          <div className={ `shadow ${style.rules}`}>
            <div dangerouslySetInnerHTML={{__html: i18n.t('invitation.rules')}} />
            <div className={ style.action }>
              {/* TODO only for group */}
              <Link to='/packets/prepare' className={ `${style.invite}` }>{ i18n.t('invitation.invite') }</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Show;
