class Task {
  constructor(api) {
    this.api = api;
  }

  create(o) {
    return this.api.axios.post('/tasks', {option: o});
  }

  complete() {
    return this.api.axios.post('/tasks/complete');
  }

  show() {
    return this.api.axios.get('/tasks/last');
  }
}

export default Task;
