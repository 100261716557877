import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Loading from '../home/loading.js';
import API from '../api/index.js';

class Oauth extends Component {
  constructor(props) {
    super(props);

    this.api = new API();
    let code = this.api.mixin.getUrlParameter('code');
    let to = this.api.mixin.getUrlParameter('return_to');
    this.state = {
      is_login: this.api.me.loggedIn(),
      code: code,
      return_to: to,
    };
  }

  componentDidMount() {
    if (!this.state.is_login) {
      this.api.me.signIn(this.state).then((resp) => {
        if (resp.error) {
          let code = resp.error.code;
          if (code === 401 || code === 403) {
            this.setState({is_login: true});
          }
          return
        }
        this.setState({is_login: true});
      });
    }
  }

  render() {
    if (this.state.is_login && this.state.return_to) {
      return (
        <Redirect to={decodeURIComponent(this.state.return_to)} />
      );
    }
    if (this.state.is_login) {
      return (
        <Redirect to='/' />
      );
    }
    return (
      <Loading />
    );
  }
}

export default Oauth;
