import Oauth from './oauth.js';
import Tips from './error.js';
import Detail from './detail.js';

export default {
  Oauth: Oauth,
  Tips: Tips,
  // components
  Detail: Detail,
};
